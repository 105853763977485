import { checkForNullValue } from 'containers/VerticalPage/nft/Overview/utils'
import { VerticalEntity } from '../../../../store/verticals/types'
import { Period } from '../../../../utils/types'
import { byYearValueIsExist, getYearFromPeriod, numberIsBetween } from 'containers/VerticalPage/common/utils'

export const getLabelsForFinancialOverview = () => [
  { key: 'total', value: 'Total revenue' },
  { key: 'withoutVat', value: 'Total w/o VAT' },
  { key: 'reported', value: 'Reported' },
  { key: 'unreported', value: 'Unreported' },
]

export const getVerticalRevenueByTotalFromEntity = (entityData: VerticalEntity | null, period: Period) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)

  let exactSum = 0
  let documentedSum = 0

  entityData.revenueSources.forEach(source => {
    const exactData = source.revenueByYear?.exact
    const documentedData = source.revenueByYear?.documented

    for (const key in exactData) {
      if (
        Object.prototype.hasOwnProperty.call(exactData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        exactSum += exactData[key]
      }
    }

    for (const key in documentedData) {
      if (
        Object.prototype.hasOwnProperty.call(documentedData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        documentedSum += documentedData[key]
      }
    }
  })

  return [
    { name: 'Estimated', value: exactSum },
    { name: 'Documented', value: documentedSum },
  ]
}

export const getTotalRevenueFromEntity = (
  entityData: VerticalEntity | null,
  period: Period,
  noDataAsNA: boolean = false,
) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)
  const data = entityData.totals.byYear

  // const years = new Set<string>()
  // Currently, these are the years we need to display, even when there is no data for them.
  const years = new Set(['2019', '2020', '2021', '2022', '2023'])

  const withoutVatIsExist = byYearValueIsExist(data, 'totalWithoutVat')

  for (const category in data) {
    Object.keys(data[category]).forEach(year => years.add(year))
  }

  const result = Array.from(years)
    .sort()
    .map(year => {
      const yearDataObject: { [key: string]: string | number | null } = {
        date: year,
        exact: checkForNullValue(data?.exact?.[year], noDataAsNA),
        total: checkForNullValue(data?.total?.[year], noDataAsNA),
        documented: checkForNullValue(data?.documented?.[year], noDataAsNA),
        estimated: checkForNullValue(data?.exact?.[year], noDataAsNA),
        reported: checkForNullValue(data?.reportedByYear?.[year], noDataAsNA),
        unreported: checkForNullValue(data?.unReportedByYear?.[year], noDataAsNA) || 0,
      }

      if (withoutVatIsExist) {
        yearDataObject.withoutVat = data?.totalWithoutVat?.[year] || 0
      }

      return yearDataObject
    })
    .filter(item => numberIsBetween(+(item.date as string), startYear, endYear))

  return result
}
