import React, { useContext, useMemo, useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { camelCase } from 'lodash'
import VerticalBAHeading from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBAHeading'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Waypoint } from 'react-waypoint'
import { useTheme } from '@mui/material/styles'
import GenericHeading from 'components/common/GenericHeading'
import CircularProgress from '@mui/material/CircularProgress'
import { useDocumentActivitiesQuery } from 'containers/VerticalPage/common/useDocumentActivitiesQuery'
import { VerticalsDetailsContext } from 'store/verticalsDetails/Context'
import { VerticalContext } from 'store/verticals/Context'
import type { CurrentZone } from 'utils/types'
import { usePeriodSetter } from 'containers/VerticalPage/common/hooks'
import { getDefaultDateValues, getHeadingValues } from 'containers/VerticalPage/common/utils'
import { getNFTHeadingValues } from './utils'
import { selectCurrentZone } from 'store/global'
import Income from 'components/Income'
import BusinessSource from 'components/BusinessSource'
import { extractRootDomainNoExt } from 'containers/NFT/specs'
import ActivityDropdown from 'containers/VerticalPage/common/ActivityDropdown/ActivityDropdown'
import DatePicker from 'components/common/DatePicker'
import { useDebounce } from 'hooks/useDebounce'
import ActivityTypesFilter from '../ActivityTypesFilter/ActivityTypesFilter'
import ExportEntityActivityCSV from '../ExportEntityActivityCSV/ExportEntityActivityCSV'
import styles from './Activity.module.scss'
import commonStyles from '../../common/styles.scss'

const calendarIconUrl = require('components/common/images/calendar.svg')

const periodFormat = 'MMM. yyyy'
const periodPopperPlacement = 'bottom-end'
const PAGE_SIZE = 100

const Activity = () => {
  const { verticalDocumentActivityStats, getVerticalDocumentActivityStats } = useContext(VerticalContext)
  const { detailsFilters, submittedDropdownOptions } = useContext(VerticalsDetailsContext)
  const defaultDateValues = getDefaultDateValues()
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const theme = useTheme()

  const { period: datePeriod, handlePeriodChange: handleBusinessActivityPeriodChange } =
    usePeriodSetter(defaultDateValues)

  const { entityId } = useParams<{ entityId: string }>()

  const [selectedActivityTypes, setSelectedActivityTypes] = useState<string[]>([])

  const datePeriodDebounced = useDebounce(datePeriod, 1000)
  const activityTypeDebounced = useDebounce(selectedActivityTypes, 1000)

  const { verticalDocumentActivity, isLoading, fetchNextPage } = useDocumentActivitiesQuery({
    entityId,
    detailsFilters,
    zoneActivityIndices: currentZone.nftActivityIndices,
    currentPeriod: datePeriodDebounced,
    pageSize: PAGE_SIZE,
    activityType: activityTypeDebounced.length > 0 ? activityTypeDebounced : undefined,
    sort: [{ activityDate: 'desc' }],
  })

  const fetchDocumentActivityStats = useCallback(async () => {
    await getVerticalDocumentActivityStats(
      entityId,
      currentZone.nftActivityIndices,
      currentZone.nftIndices,
      datePeriodDebounced,
      undefined,
      // `[{"revenueSource":"blaksoot"}]`,
      submittedDropdownOptions.filter(item => item.isChecked).length > 0
        ? JSON.stringify(
            submittedDropdownOptions
              .filter(item => item.isChecked)
              .map(item => ({ revenueSource: item.revenueSource })),
          )
        : undefined,
    )
  }, [entityId, datePeriodDebounced, currentZone, submittedDropdownOptions, getVerticalDocumentActivityStats])

  useEffect(() => {
    if (!entityId || !currentZone) return
    fetchDocumentActivityStats

    fetchDocumentActivityStats()
  }, [entityId, currentZone, fetchDocumentActivityStats])

  const headingValues = useMemo(() => getHeadingValues(submittedDropdownOptions), [submittedDropdownOptions])

  const nftHeadingValues = useMemo(
    () => getNFTHeadingValues(verticalDocumentActivityStats.activityTypes),
    [verticalDocumentActivityStats],
  )

  const handleEnterWaypoint = async () => fetchNextPage()

  const shouldRenderWaypoint = useCallback(
    (index: number) => {
      if (!verticalDocumentActivity) return false
      const hasMoreToFetch = verticalDocumentActivity.length >= PAGE_SIZE
      const isAtLastFetchedItem = index === verticalDocumentActivity.length - 1
      return hasMoreToFetch && isAtLastFetchedItem
    },
    [verticalDocumentActivity],
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gridRow: '2 / 3',
        bgcolor: 'white',
        overflow: 'hidden',
        padding: 0,
        borderRadius: '16px',
        gridTemplateRows: 'max-content',
      }}
    >
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <CircularProgress size={40} thickness={4} />
        </div>
      ) : (
        <>
          <VerticalBAHeading customSx={{ padding: '16px 24px' }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={{ lg: 1, xxxl: 3 }}>
                <GenericHeading
                  sx={{
                    fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                    fontWeight: 600,
                  }}
                >
                  {headingValues.text}
                </GenericHeading>
                <Stack
                  direction='row'
                  alignItems='center'
                  sx={{
                    fontFamily: 'Dosis',
                    fontSize: { md: 14, lg: 16, xl: 18, xxl: 34, xxxl: 44 },
                    fontWeight: 500,
                  }}
                  className={commonStyles.headingValuesContainer}
                >
                  <Income value={nftHeadingValues.buy} className={`${commonStyles.income}`} />
                  Buys
                  {','}
                  <Income
                    value={nftHeadingValues.royalties}
                    className={`${commonStyles.income} ${styles.incomeMargin}`}
                  />
                  Royalties
                  {','}
                  <Income value={nftHeadingValues.sale} className={`${commonStyles.income} ${styles.incomeMargin}`} />
                  Sales
                  {','}
                  <span className={styles.incomeMargin}>{headingValues.activities} Transactions</span>
                </Stack>
                <ActivityDropdown onSubmitCallback={fetchDocumentActivityStats} />
              </Stack>
              <div className={styles.actions}>
                <ExportEntityActivityCSV
                  activityIndices={currentZone.nftActivityIndices}
                  selectedActivityTypes={selectedActivityTypes}
                  datePeriod={datePeriod}
                  selectedRevenueSources={submittedDropdownOptions
                    .filter(item => item.isChecked)
                    .map(item => item.revenueSource)}
                />
                <ActivityTypesFilter
                  selectedActivityTypes={selectedActivityTypes}
                  setSelectedActivityTypes={setSelectedActivityTypes}
                />
                <DatePicker
                  periodStart={datePeriod.start}
                  periodEnd={datePeriod.end}
                  handlePeriodChange={handleBusinessActivityPeriodChange}
                  format={periodFormat}
                  popperPlacement={periodPopperPlacement}
                  isCharts
                  iconUrl={calendarIconUrl}
                />
              </div>
            </Stack>
          </VerticalBAHeading>
          <Stack
            sx={{
              overflow: isLoading ? 'hidden' : 'auto',
              ...theme.mixins.customScrollBar(),
            }}
            className={styles.tableContainer}
          >
            <div className={styles.header}>
              {['Item', 'Type', 'Price', 'From', 'To', 'Time', 'Evidence'].map(value => (
                <div key={value} className={styles.headerItem}>
                  {value}
                </div>
              ))}
            </div>
            {verticalDocumentActivity?.map((row, index) => {
              const domain = extractRootDomainNoExt(row.activityUrl)

              return (
                <div key={index} className={styles.row}>
                  <div className={styles.item}>
                    <div>
                      <div className={styles.revenueSource}>{row.revenueSource}</div>
                      <div className={styles.activityItem}>{row.activityItem}</div>
                    </div>
                  </div>
                  <div className={styles.item}>{row.activityType}</div>
                  <div className={`${styles.item} ${styles.price}`}>
                    {row.revenueOtherCoin && (
                      <span className={styles.cryptoWrapper}>
                        <Income
                          value={row.revenueOtherCoin}
                          currency={row.otherCoin}
                          className={styles.cryptoPrice}
                          ethIconStyle={{
                            height: '15px',
                            marginBottom: '4px',
                            paddingRight: '5px',
                          }}
                        />
                      </span>
                    )}
                    {row.revenue && (
                      <span className={styles.dollarsWrapper}>
                        <Income value={row.revenue} currency={'USD'} className={styles.dollarsPrice} />
                      </span>
                    )}
                  </div>
                  <div className={styles.item}>{row.activityFrom}</div>
                  <div className={styles.item}>{row.activityTo}</div>
                  <div className={styles.item}>{row.activityDate}</div>
                  <div className={`${styles.item} ${styles.url}`}>
                    <a key={row.activityUrl} href={row.activityUrl} target='_blank' rel='noreferrer'>
                      <BusinessSource source={camelCase(domain)} isClickable className={styles.svg} />
                    </a>
                  </div>
                  {shouldRenderWaypoint(index) && <Waypoint onEnter={handleEnterWaypoint} />}
                </div>
              )
            })}
          </Stack>
        </>
      )}
    </Box>
  )
}

export default Activity
