import React, { useEffect } from 'react'
import Select, { components, DropdownIndicatorProps, SingleValue, StylesConfig } from 'react-select'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ShopSelectOption } from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/types'
import { CartCheckProduct } from 'store/verticals/types'

const CustomDropdownIndicator = (props: DropdownIndicatorProps<any>) => {
  const { selectProps } = props
  const { menuIsOpen } = selectProps
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon
        sx={{
          transition: 'transform 0.3s ease',
          transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </components.DropdownIndicator>
  )
}

const customStyles: StylesConfig<ShopSelectOption, false> = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    padding: '0px 8px 0px 0px',
    fontSize: '1rem',
    textAlign: 'start',
    boxShadow: 'none',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderColor: '#d9d9d9',
      cursor: 'pointer',
    },
    borderBottomLeftRadius: state.menuIsOpen ? '0px' : '8px',
    borderBottomRightRadius: state.menuIsOpen ? '0px' : '8px',
  }),
  menu: provided => ({
    ...provided,
    fontFamily: 'Source Sans Pro',
    border: '1px solid #d9d9d9',
    borderTop: 'none',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxHeight: '240px',
    overflowY: 'auto',
    marginTop: 0,
  }),
  menuList: provided => ({
    ...provided,
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    maxHeight: '200px',
    padding: 0,
    overflowY: 'auto',
    borderBottom: '1px solid #d9d9d9',
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'start',
    display: 'flex',
    alignItem: 'center',
    alignSelf: 'center',
    fontFamily: 'Source Sans Pro',
    padding: '8px 16px',
    fontSize: '1rem',

    backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
    color: '#333',
    borderBottom: '1px solid #d9d9d9',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#6e8192',
    padding: '0 8px',
  }),
  valueContainer: provided => ({
    ...provided,
    fontFamily: 'Source Sans Pro',
  }),
}

interface ShopSelectProps {
  options: ShopSelectOption[]
  handleSelectShopChange: (option: SingleValue<ShopSelectOption>) => void
  currentProducts: CartCheckProduct[]
}

const ShopSelect = ({ options, currentProducts, handleSelectShopChange }: ShopSelectProps) => {
  useEffect(() => {
    if (currentProducts && currentProducts?.length === 0) {
      handleSelectShopChange(options?.[0])
    }
  }, [options, handleSelectShopChange, currentProducts])
  return (
    <Select
      options={options}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      styles={customStyles}
      placeholder='Choose Shop'
      defaultValue={options?.[0] || null}
      onChange={(option: ShopSelectOption) => handleSelectShopChange(option)}
    />
  )
}

export default ShopSelect
